// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgRepost = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 18 18"
    ref={ref}
    {...props}
  >
    <path
      fill="#000"
      d="M.004 10.285V4.5A1.93 1.93 0 0 1 1.93 2.573h13.874l-1.478-1.471a.645.645 0 0 1 .913-.913l2.57 2.57a.64.64 0 0 1 0 .913l-2.57 2.57a.643.643 0 0 1-1.054-.209.64.64 0 0 1 .141-.703l1.478-1.472H1.932a.643.643 0 0 0-.643.643v5.784a.643.643 0 0 1-1.285 0m17.35-3.213a.643.643 0 0 0-.643.642v5.784a.643.643 0 0 1-.642.643H2.194l1.478-1.472a.645.645 0 0 0-.913-.913l-2.57 2.57a.64.64 0 0 0 0 .913l2.57 2.57a.643.643 0 0 0 1.054-.208.64.64 0 0 0-.141-.704l-1.478-1.471h13.874a1.93 1.93 0 0 0 1.927-1.928V7.714a.643.643 0 0 0-.642-.642"
    />
  </svg>
);
export const RepostIcon = forwardRef(SvgRepost);
