// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgQuote = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 3.75c-.69 0-1.25.56-1.25 1.25v14.862l2.064-2.58A2.75 2.75 0 0 1 7.96 16.25H19c.69 0 1.25-.56 1.25-1.25V5c0-.69-.56-1.25-1.25-1.25zM2.25 5A2.75 2.75 0 0 1 5 2.25h14A2.75 2.75 0 0 1 21.75 5v10A2.75 2.75 0 0 1 19 17.75H7.961c-.38 0-.739.173-.976.47l-2.33 2.913c-.798.996-2.405.433-2.405-.843z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.5 7.75a.25.25 0 0 0-.25.25v1c0 .138.112.25.25.25h1.25V8a.25.25 0 0 0-.25-.25zm.981 3a4 4 0 0 1-.206.3c-.337.449-.796.91-1.305 1.42a.75.75 0 1 0 1.06 1.06l.022-.021c.484-.485 1.016-1.016 1.423-1.559s.775-1.205.775-1.95V8A1.75 1.75 0 0 0 9.5 6.25h-1A1.75 1.75 0 0 0 6.75 8v1c0 .966.784 1.75 1.75 1.75zM14.5 7.75a.25.25 0 0 0-.25.25v1c0 .138.112.25.25.25h1.25V8a.25.25 0 0 0-.25-.25zm.981 3a4 4 0 0 1-.206.3c-.337.449-.796.91-1.305 1.42a.75.75 0 1 0 1.06 1.06l.022-.021c.484-.485 1.016-1.016 1.423-1.559s.775-1.205.775-1.95V8a1.75 1.75 0 0 0-1.75-1.75h-1A1.75 1.75 0 0 0 12.75 8v1c0 .966.784 1.75 1.75 1.75z"
      clipRule="evenodd"
    />
  </svg>
);
export const QuoteIcon = forwardRef(SvgQuote);
