// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgLink = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.857 16.245h-1.714c-2.408 0-4.393-2.166-4.393-4.248a.75.75 0 0 0-1.5 0c0 2.902 2.649 5.748 5.893 5.748h1.714c3.234 0 5.893-2.553 5.893-5.748 0-2.751-1.976-5.03-4.588-5.606a6 6 0 0 0-1.305-.141.75.75 0 0 0 0 1.5q.508.001.982.106c1.968.434 3.411 2.137 3.411 4.142 0 2.325-1.946 4.247-4.393 4.247M7.143 7.75h1.714c2.408 0 4.393 2.166 4.393 4.248a.75.75 0 0 0 1.5 0c0-2.902-2.649-5.748-5.893-5.748H7.143c-3.234 0-5.893 2.553-5.893 5.748 0 2.752 1.976 5.03 4.589 5.606q.631.14 1.304.141a.75.75 0 1 0 0-1.5q-.508-.001-.981-.106c-1.969-.434-3.412-2.136-3.412-4.141 0-2.326 1.946-4.248 4.393-4.248"
    />
  </svg>
);
export const LinkIcon = forwardRef(SvgLink);
